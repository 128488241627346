import React, { useState } from "react";
import { Link } from "gatsby";
import { Location } from "@reach/router";
import routes from "../../Routes";
import Help from "../Accounts/Help";
import isBrowser from "../../Auth/isBrowser";
import HelpButton from "./HelpButton";
import { useAuth0 } from "../../hooks/useAuth0";

const activeCSS =
  "text-white font-bold uppercase group-hover:text-white py-4 border-b-4 border-blue-500";
const inactive =
  "text-gray-500 font-bold uppercase py-4 border-b-4 border-transparent group-hover:text-white";
const dropdownCSS =
  "block bg-white hover:bg-gray-100 transition py-2 px-4 border-b";
const Header = () => {
  if (!isBrowser) return null;
  const { loading, isAuthenticated, logout } = useAuth0();
  const [helpShowing, setHelpShowing] = useState(false);

  const Menu = () => {
    if (!isAuthenticated || loading) return null;

    return (
      <Location>
        {({ location }) => (
          <>
            <div className="flex">
              <div className="group relative flex items-center mx-4 z-20">
                <Link
                  to={routes.APP}
                  className={
                    location.pathname === routes.APP ? activeCSS : inactive
                  }
                >
                  Customer Records
                </Link>
              </div>
              <div className="group relative flex items-center mx-4 z-20">
                <Link
                  to={routes.SEARCH}
                  className={
                    location.pathname === routes.SEARCH ? activeCSS : inactive
                  }
                >
                  Search
                </Link>
              </div>
              <div className="group relative flex items-center mx-4 z-20">
                <a
                  href="#"
                  className={
                    location.pathname.includes("report") ? activeCSS : inactive
                  }
                >
                  Reports
                </a>
                <div className="nav__dropdown absolute w-64 left-0 right-0 top-0 hidden group-hover:block  ">
                  <div className="shadow bg-white rounded transition mt-16 rounded overflow-hidden">
                    <Link className={dropdownCSS} to={routes.IDX_ROSTER}>
                      Idx Roster
                    </Link>
                    <Link className={dropdownCSS} to={routes.HOMEPAGE}>
                      Homepage template usage
                    </Link>
                    <Link className={dropdownCSS} to={routes.CRM}>
                      CRM provider usage
                    </Link>
                    <Link className={dropdownCSS} to={routes.STATES}>
                      LeadSites by State
                    </Link>
                    <Link className={dropdownCSS} to={routes.DELINQUENT}>
                      Delinquent Status
                    </Link>
                  </div>
                </div>
              </div>
              <div className="group relative flex items-center mx-4 z-20">
                <a
                  href="#"
                  className={
                    location.pathname.includes("build") ? activeCSS : inactive
                  }
                >
                  Sites
                </a>
                <div className="nav__dropdown absolute w-64 left-0 right-0 top-0 hidden group-hover:block  ">
                  <div className="shadow bg-white rounded transition mt-16 rounded overflow-hidden">
                    <Link to={routes.BUILD} className={dropdownCSS}>
                      Build A Site
                    </Link>
                    <Link to={routes.DEPLOY} className={dropdownCSS}>
                      Deploy LeadSites
                    </Link>
                  </div>
                </div>
              </div>
              <div className="group relative flex items-center mx-4 z-20">
                <Link
                  to={routes.EVENTS}
                  className={
                    location.pathname === routes.EVENTS ? activeCSS : inactive
                  }
                >
                  Site Events
                </Link>
              </div>
            </div>
            <div className="flex items-center">
              <HelpButton
                updateHelp={setHelpShowing}
                helpShowing={helpShowing}
              />
              <button
                type="button"
                onClick={() =>
                  logout({
                    returnTo: `${window.location.protocol}//${window.location.host}`,
                  })
                }
                className="button"
              >
                Log Out
              </button>
            </div>
          </>
        )}
      </Location>
    );
  };

  return (
    <header className="bg-gray-900 shadow px-4 mt0">
      <div className="flex justify-between m-auto items-center">
        <Link className="text-black no-underline" to={routes.HOME}>
          <img
            alt="keesh money"
            src="https://www.easyagentpro.com/wp-content/themes/EasyAgentPro/out/images/logo.png"
            width="200px"
            className="m-0 p-2"
          />
        </Link>

        <Menu />
      </div>
      <div className="container m-auto">{helpShowing && <Help />}</div>
    </header>
  );
};

export default Header;
