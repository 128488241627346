import React from "react";
import styled from "styled-components";

const Help = props => {
	return (
		<HelpContainer className="rounded">
			<h2 className="text-xl text-gray-500 text-center pt-6">
				Searching for client information is a piece of cake. 🍰
			</h2>
			<div className="flex pt-6">
				<div className="bg-gray-100 shadow-lg w-1/3 m-2 rounded p-6 text-center border-t-2 border-blue-500 hover:bg-white transition">
					<img
						alt="search"
						src="/search.svg"
						className="h-20 m-auto mb-8"
					/>
					<h4 className="text-base">Start Your Search</h4>
					<p
						className="text-sm text-gray-700 m-auto mt-4"
						style={{ maxWidth: "250px" }}
					>
						Use the column header to enter a search, or click to
						organize by ascending or descending.
					</p>
				</div>
				<div className="bg-gray-100 shadow-lg w-1/3 m-2 rounded p-6 text-center border-t-2 border-blue-500 hover:bg-white transition">
					<img
						alt="search"
						src="/user.svg"
						className="h-20 m-auto mb-8"
					/>
					<h4 className="text-base">View Results</h4>
					<p
						className="text-sm text-gray-700 m-auto mt-4"
						style={{ maxWidth: "250px" }}
					>
						Most data is available for viewing on this page. To see
						the full profile, click their email address or the user
						icon.
					</p>
				</div>
				<div className="bg-gray-100 shadow-lg w-1/3 m-2 rounded p-6 text-center border-t-2 border-blue-500 hover:bg-white transition">
					<img
						alt="search"
						src="/account.svg"
						className="h-20 m-auto mb-8"
					/>
					<h4 className="text-base">Actions and More</h4>
					<p
						className="text-sm text-gray-700 m-auto mt-4"
						style={{ maxWidth: "250px" }}
					>
						On the user profile page you can find all data, as well
						as quick access to any accounts we have for a user.
					</p>
				</div>
			</div>
		</HelpContainer>
	);
};

const HelpContainer = styled.div`
	position: relative;
	background-image: linear-gradient(0deg, #4299e1, #1a202c 30%);
`;
export default Help;
