import React from 'react';
import { Helmet } from 'react-helmet';
import { TypographyStyle } from 'react-typography';
import typography from '../../utils/typography';
import Header from './Header';

// Global styles and component-specific styles.
import './global.css';

const Layout = ({ children }) => {
	return (
		<div className="text-sm sm:text-sm lg:text-base">
			<Helmet>
				<title>{process.env.GATSBY_NAME}</title>
				<TypographyStyle typography={typography} />
				<link href="/icon.png" type="image/png" rel="icon" />
				<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
			</Helmet>
			<Header />
			<main>{children}</main>
		</div>
	);
};
export default Layout;
