import React from 'react';
import PropTypes from 'prop-types';

function HelpButton(props) {
	const { helpShowing, updateHelp } = props;
	return (
		<button className="button mr-2 bg-transparent --is-blue" onClick={() => updateHelp(!helpShowing)} type="button">
			<i className="material-icons">{helpShowing ? 'expand_less' : 'expand_more'}</i>
			Help
		</button>
	);
}

HelpButton.propTypes = {
	helpShowing: PropTypes.bool.isRequired,
	updateHelp: PropTypes.func.isRequired
};
export default HelpButton;
