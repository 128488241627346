const routes = {
	APP: '/accounts',
	SEARCH: '/accounts/search',
	PROFILE: '/accounts/profile',
	HOME: '/',
	HOMEPAGE: '/accounts/homepage-report',
	CRM: '/accounts/crm-report',
	STATES: '/accounts/states-report',
	DELINQUENT: '/accounts/delinquent-report',
	BUILD: '/accounts/build',
	DEPLOY: '/accounts/build-deploy',
	EVENTS: '/accounts/events',
	IDX_ROSTER: '/accounts/idx-roster',
};

export default routes;
